<template>
  <div style="display: flex" class="w-100 flex-wrap">
    <template v-if="$store.state.header.user && $store.state.header.user.addresses != ''">
      <AddressDesktop :seletable="selectable" ref="addresses" v-for="(address, index) in $store.state.header.user.addresses"
                       :index="index" :key="index" :address="address"
                       v-model="selected_address" :editAddress="editAddress"></AddressDesktop>
    </template>
    <div v-else class="text-center col-12">
      <span>
        <ArrowLeftIcon></ArrowLeftIcon>
      </span>
      <h6 class="mt-3 text-color-666 fontsize17">
        لیست آدرس ها خالی می باشد.
      </h6>
    </div>
    <AddressModal :should-load-provinces="true" id="addressModalDesktop" :address="address" :mode="mode"></AddressModal>
  </div>

</template>

<script>
import addressesMixin from "./addressesMixin";

export default {
  name: "AddressesDesktop",
  mixins: [addressesMixin]
}
</script>

<style scoped>

</style>