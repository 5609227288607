import LocationIcon from "../svgs/LocationIcon";

export default {
  components: {LocationIcon},
  data() {
    return {
      radioValue: this.value && this.address.id == this.value.id,
      disabled: false
    }
  },
  methods: {
    addressSelected(e) {
      console.log(e)
      this.$emit('input', this.address)
    },
    setAddress() {
      this.$emit('input', this.address)
      setTimeout(() => {
        this.radioValue = this.address.id
      }, 0)
    },
    deselect() {
      this.radioValue = null;
    },
    deleteItem(id) {
      let item = {
        ...this.value
      };
      window.swal({
          title: "آیتم حذف شود؟",
          text: "این عملیات غیر قابل بازگشت خواهد بود",
          icon: "warning",

          showCancelButton: true,
          showConfirmButton: true,
          dangerMode: true,
          buttons: ["خیر", "بله حذف کن"]
        })
        .then((willDelete) => {
          if (willDelete) {
            this.$axios.delete(`/api/profile/address/${id}`)
              .then((response) => {
                this.$store.state.header.user.addresses = this.$store.state.header.user.addresses.filter(item =>
                  item.id != id)
                window.swal({
                  icon: "success",
                  showConfirmButton: true,
                  text: response.data.message,
                  button: "باشه",
                })
              })
              .catch(error => {
                window.swal({
                  title: 'خطا',
                  showConfirmButton: true,
                  text: error.response.data.message,
                  button: "باشه",

                })
              })
          }
        })
        .catch(() => {

        })

    },
  },
  props: {
    address: Object,
    value: {
      default: null,
      type: Object
    },
    seletable: {
      default: false,
      type: Boolean
    },
    editAddress: Function,
    index: Number
  },
}