import AddressModal from "./AddressModal";
import AddressDesktop from "./AddressDesktop";
import AddressMobile from "./AddressMobile";
import ArrowLeftIcon from "../svgs/ArrowLeftIcon";

export default {
  components: {AddressModal, AddressDesktop, AddressMobile, ArrowLeftIcon},
  watch: {
    selected_address: function (newVal) {
      if (this.$refs.addresses) {
        this.$refs.addresses.forEach(item => {
          item.deselect();
        })
      }
      this.$emit('input', newVal)
    }
  },
  props: {
    value: Object,
    selectable: {
      default: true,
      type: Boolean
    }
  },
  data() {
    return {
      selected_address: this.value,
      address: {
        city_id: null,
        first_name: '',
        last_name: '',
        mobile: '',
        telephone: '',
        postal_code: '',
        plaque: '',
        national_code: '',
        address: '',
        latitude: '',
        longitude: '',
      },
      mode: 'new',
    }
  },
  methods: {
    editAddress(item) {
      this.address = item
      this.mode = 'edit'
      this.showModal()
    },
    addressModal() {
      this.address = {
        city: null,
        first_name: '',
        last_name: '',
        mobile: '',
        telephone: '',
        postal_code: '',
        plaque: '',
        national_code: '',
        address: '',
        latitude: '',
        longitude: '',
      }
      this.mode = 'new'
      this.showModal()
    },
    showModal() {
      if (this.$options.name == 'AddressesDesktop') {
        this.$('#addressModalDesktop').modal('show')
      } else {
        this.$('#addressModalMobile').modal('show')
      }
    }
  }
}
