<template>
  <div style="display: flex" class="w-100 flex-wrap">
    <template v-if="$store.state.header.user && $store.state.header.user.addresses != []">
      <AddressMobile :seletable="selectable" ref="addresses" v-for="(address, index) in $store.state.header.user.addresses"
                     :index="index" :key="index" :address="address"
                     v-model="selected_address" :editAddress="editAddress"></AddressMobile>
    </template>
    <div v-else class="d-flex w-100 justify-content-center flex-column text-center my-3">
      <span>
          <ArrowLeftIcon></ArrowLeftIcon>
      </span>

      <h5 class="mt-4 text-color-666 font-weight-bold fontsize17">
        لیست آدرس ها خالی می باشد.
      </h5>
    </div>
    <AddressModal :should-load-provinces="false" id="addressModalMobile" :address="address" :mode="mode"></AddressModal>
  </div>

</template>

<script>
import addressesMixin from "./addressesMixin";

export default {
  name: "AddressesMobile",
  mixins: [addressesMixin]
}
</script>

<style scoped>

</style>